<template>
    <div>
      <v-page id="vacature" justify="center" align="center" class="d-flex text-center mt-16">
        <v-container>
          <v-row>
            <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("vacature.header") }}</v-col>
          </v-row>
          <v-row>
            <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("vacature.subheader") }}</h1></v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="12" lg="8" xl="6">
              <i18n path="vacature.intro" tag="label" for="vacature.intro">
              </i18n>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1" v-for="(vacature, idx) in vacatures" :key="idx">
              <room-card :src="vacature.featured_image" :header-text="vacature.title" :to="{name: 'VacatureSingular', params: { id: vacature.id, locale: $i18n.locale}}" style="width: 100%">
                {{ vacature.excerpt }}
              </room-card>
            </v-col>
          </v-row>
        </v-container>
      </v-page>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
  import RoomCard from "@/components/RoomCard";
  import axios from "../http";
  import VPage from "@/components/VPage";
  import Footer from "@/views/Footer";
  export default {
    name: "December",
    components: {VPage, RoomCard, Footer},
    data() {
    return {
      vacatures: []
    }
    },
    async mounted() {
      let resp = await axios.get(`/p/${this.$i18n.locale}/vacature?order=created_at`);
      this.vacatures = resp.data.data;
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  